import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function useUsersList() {
  // Use toast
  const toast = useToast();
  const refCompanyListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    {
      key: 'name',
      label: 'Tên công ty',
      thStyle: 'vertical-align: middle;',
    },
    {
      key: 'shortName',
      label: 'Tên viết tắt',
      thStyle: 'vertical-align: middle;',
    },
    {
      key: 'isVerify',
      label: 'Xác thực',
      thStyle: 'vertical-align: middle;',
    },
    {
      key: 'isActive',
      label: 'Kích hoạt',
      thStyle: 'vertical-align: middle;',
    },
    {
      key: 'createdAt',
      label: 'Ngày tạo',
      thStyle: 'vertical-align: middle;',
    },
    { key: 'actions', label: '' },
  ];
  const perPage = ref(10);
  const totalContract = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const companyNameFilter = ref(null);
  const shortNameFilter = ref(null);
  const verifyFilter = ref(null);
  const createdAtFilter = ref(null);
  // const searchQuery = ref('');
  // const sortBy = ref('id');
  // const isSortDirDesc = ref(true);
  // const roleFilter = ref(null);
  // const planFilter = ref(null);
  // const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refCompanyListTable.value
      ? refCompanyListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalContract.value,
    };
  });

  const refetchData = () => {
    refCompanyListTable.value.refresh();
  };

  watch(
    [currentPage, perPage, shortNameFilter, companyNameFilter, verifyFilter, createdAtFilter],
    () => {
      refetchData();
    },
  );

  const fetchPayrollCompanys = (ctx, callback) => {
    store
      .dispatch('app-payroll/fetchPayrollCompanys', {
        limit: perPage.value,
        page: currentPage.value,
        name: companyNameFilter.value,
        shortName: shortNameFilter.value,
        isVerify: verifyFilter.value,
        createdAt: createdAtFilter.value,
      })
      .then((response) => {
        const { data, meta } = response.data;
        callback(data);
        totalContract.value = meta.total;
      })
      .catch((error) => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserStatusVariant = (value) => {
    if (value === 0) return 'success';
    if (value === 1) return 'purple';
    if (value === 2 || value === 3) return 'dark';
    if (value === 4) return 'primary';
    if (value === 5) return 'info';
    if (value === 6) return 'warning';
    if (value === 7) return 'dark';
    if (value === 8) return 'danger';
    return 'primary';
  };

  const resolveStatus = (value) => {
    if (value === 0) return 'Mới';
    if (value === 1) return 'Chờ duyệt';
    if (value === 2) return 'Từ chối';
    if (value === 3) return 'KH hủy';
    if (value === 4) return 'Chờ đầu tư';
    if (value === 5) return 'Chờ giải ngân';
    if (value === 6) return 'Thu nợ';
    if (value === 7) return 'Đóng';
    if (value === 8) return 'Trễ';
    return '';
  };

  const verifyOptions = [
    { label: 'Đã xác thực', value: true },
    { label: 'Chưa xác thực', value: false },
  ];

  const typeOptions = [
    { label: 'Khoản ứng', value: 0 },
    { label: 'Lead', value: 2 },
  ];
  return {
    fetchPayrollCompanys,
    tableColumns,
    perPage,
    currentPage,
    totalContract,
    dataMeta,
    perPageOptions,
    refCompanyListTable,
    resolveUserStatusVariant,
    refetchData,
    resolveStatus,
    verifyOptions,
    companyNameFilter,
    shortNameFilter,
    verifyFilter,
    createdAtFilter,
  };
}
