<template>
  <div>
    <payroll-company-filter
      :verify-options.sync="verifyOptions"
      :company-name.sync="companyNameFilter"
      :short-name.sync="shortNameFilter"
      :is-verify.sync="verifyFilter"
      :created-at.sync="createdAtFilter"
    />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="6"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>
          <b-col cols="6" md="6" class="d-flex align-items-center justify-content-end mb-1 mb-md-0">
            <b-button v-b-modal.modal-create-company variant="primary">Tạo mới</b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refCompanyListTable"
        class="position-relative cursor-pointer"
        :items="fetchPayrollCompanys"
        responsive
        :fields="tableColumns"
        :busy="busy"
        show-empty
        empty-text="No matching records found"
        @row-clicked="myHandler"
      >
        <!-- Column: customer -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar size="32" :src="data.item.logoImage" :variant="'light-primary'" />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.name }}
            </span>
          </b-media>
        </template>
        <template #cell(createdAt)="data">
          <format-time :time="data.item.createdAt" />
        </template>
        <template #cell(shortName)="data">
          {{ data.item.shortName || '-' }}
        </template>
        <template #cell(isVerify)="data">
          <feather-icon
            v-if="data.item.isVerify"
            icon="CheckCircleIcon"
            size="20"
            class="text-success"
          />
          <feather-icon v-else icon="XCircleIcon" size="20" class="text-danger" />
        </template>
        <template #cell(isActive)="data">
          <b-form-checkbox
            :checked="data.item.isActive"
            name="check-button"
            switch
            @change="(val) => handleUpdateActive(data.item.id, val)"
          />
        </template>
        <!-- <template #cell(actions)="data">
          <b-button variant="outline-danger" @click="handleDeleteCompany(data.item.id)"
            >Xoá</b-button
          >
        </template> -->
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalContract"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" class />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      id="modal-create-company"
      title="Thêm công ty"
      cancel-title="Huỷ"
      size="lg"
      @ok="handleCreateCompany"
      @reset="resetModal"
      @hidden="resetModal"
    >
      <b-row>
        <b-col cols="12" md="6" class="mb-md-0 mb-2">
          <label>Tên công ty<span class="required">*</span></label>
          <b-form-input v-model="companyName" class="d-inline-block mr-1" />
        </b-col>
        <b-col cols="12" md="6" class="mb-md-0 mb-2">
          <label>Tên viết tắt</label>
          <b-form-input v-model="shortName" class="d-inline-block mr-1" />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="12" class="mb-md-0 mb-2">
          <label>Mã số thuế<span class="required">*</span></label>
          <b-form-input v-model="taxCode" class="d-inline-block mr-1" />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="12" class="mb-md-0 mb-2">
          <label>Địa chỉ<span class="required">*</span></label>
          <b-form-input v-model="address" class="d-inline-block mr-1" />
        </b-col>
      </b-row>

      <p class="my-1">Sản phẩm<span class="required">*</span></p>
      <b-row v-for="(products, idx) in listProduct" :key="idx" class="align-items-center">
        <b-col cols="12" md="2" class="mb-md-0 mb-2">
          <label>Kì hạn</label>
          <b-form-input
            class="d-inline-block"
            type="number"
            @input="(val) => updateProduct(val, 'term', idx)"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Tần suất</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="durationOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => updateProduct(val, 'duration', idx)"
          />
        </b-col>
        <b-col cols="12" md="2" class="mb-md-0 mb-2 relative">
          <label>Phần trăm phí</label>
          <b-form-input
            class="d-inline-block pr-2"
            type="number"
            @input="(val) => updateProduct(val, 'feeRate', idx)"
          />
          <span class="absolute-percent">%</span>
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Sản phẩm</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="productTypeOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => updateProduct(val, 'productType', idx)"
          />
        </b-col>
        <feather-icon
          v-if="listProduct.length > 1"
          icon="Trash2Icon"
          class="text-danger mt-2"
          size="20"
          role="button"
          @click="removeProduct(idx)"
        />
      </b-row>
      <div
        class="justify-content-center d-flex align-items-center border-bottom mt-1"
        style="width: fit-content; margin: 0 auto"
      >
        <p role="button" @click="addProduct">Thêm sản phẩm</p>
        <feather-icon icon="PlusIcon" />
      </div>
    </b-modal>
    <b-modal
      id="modal-delete-company"
      title="Xác nhận"
      ok-title="Xác nhận"
      cancel-title="Huỷ"
      @ok="handleConfirmDelete"
      @reset="resetModal"
    >
      <p>Bạn có chắc chắn muốn xoá Công ty này?</p>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormCheckbox,
  BModal,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import { VueAutosuggest } from 'vue-autosuggest';
import * as moment from 'moment';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import permissionCode from '@/constants/permissionCode';
import { createCompany, updateCompany, deleteCompany } from '@/apis/apiPayroll';
import constRouter from '@/constants/constRouter';
import { numberWithCommas, randomId } from '@core/utils/utils';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import FormatTime from '@core/components/format-time/FormatTime.vue';
import PayrollCompanyFilter from './PayrollCompanyFilter.vue';
import usePayrollCompanyList from './usePayrollCompanyList';
import payrollStoreModule from '../../payrollStoreModule';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BFormCheckbox,
    vSelect,
    VueAutosuggest,
    PayrollCompanyFilter,
    FormatTime,
  },
  setup() {
    const PAYROLL_APP_STORE_MODULE_NAME = 'app-payroll';
    // Register module
    if (!store.hasModule(PAYROLL_APP_STORE_MODULE_NAME)) {
      store.registerModule(PAYROLL_APP_STORE_MODULE_NAME, payrollStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PAYROLL_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(PAYROLL_APP_STORE_MODULE_NAME);
      }
    });
    const isAddNewUserSidebarActive = ref(false);
    const {
      fetchPayrollCompanys,
      tableColumns,
      perPage,
      currentPage,
      totalContract,
      dataMeta,
      perPageOptions,
      refetchData,
      refCompanyListTable,
      // UI
      companyNameFilter,
      shortNameFilter,
      verifyOptions,
      verifyFilter,
      createdAtFilter,
    } = usePayrollCompanyList();
    return {
      // Sidebar
      isAddNewUserSidebarActive,
      refCompanyListTable,
      fetchPayrollCompanys,
      tableColumns,
      perPage,
      currentPage,
      totalContract,
      dataMeta,
      perPageOptions,
      refetchData,

      // Filter
      avatarText,
      numberWithCommas,
      shortNameFilter,
      companyNameFilter,
      moment,
      permissionCode,
      verifyOptions,
      verifyFilter,
      createdAtFilter,
    };
  },
  data() {
    return {
      limit: 10,
      companyName: '',
      shortName: '',
      address: '',
      taxCode: '',
      listProduct: [
        {
          term: '',
          duration: '',
          feeRate: '',
          isActive: true,
        },
      ],
      durationOptions: [
        { label: 'Ngày', value: 'day' },
        { label: 'Tuần', value: 'week' },
        { label: 'Tháng', value: 'month' },
      ],
      productTypeOptions: [
        { label: 'EWA', value: 'EWA' },
        { label: 'Payroll', value: 'PAYROLL' },
      ],
      companyId: '',
      busy: false,
    };
  },
  methods: {
    resetModal() {
      this.companyName = '';
      this.companyId = '';
      this.shortName = '';
      this.address = '';
      this.taxCode = '';
      this.listProduct = [
        {
          term: '',
          duration: '',
          feeRate: '',
          isActive: true,
        },
      ];
    },
    addProduct() {
      this.listProduct.push({
        term: '',
        duration: '',
        feeRate: '',
        productType: '',
        isActive: true,
      });
    },
    removeProduct(idx) {
      this.listProduct.splice(idx, 1);
    },
    updateProduct(val, key, idx) {
      this.listProduct[idx][key] = val;
      if (!this.listProduct[idx].id) {
        this.listProduct[idx].id = randomId();
      }
    },
    handleCreateCompany(bvModalEvent) {
      bvModalEvent.preventDefault();
      if (this.listProduct.some((item) => !item.term || !item.duration || !item.feeRate)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Dữ liệu không hợp lệ',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Sản phẩm không được để trống',
          },
        });
        return;
      }
      const productTypeCompany = this.listProduct.filter((item) => item.productType === 'EWA');
      if (productTypeCompany.length > 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Dữ liệu không hợp lệ',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Chỉ được 1 sản phẩm EWA',
          },
        });
        return;
      }
      if (
        productTypeCompany.map((item) => item.term)[0] > 1 ||
        productTypeCompany.map((item) => item.duration)[0] !== 'month'
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Dữ liệu không hợp lệ',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Sản phẩm EWA chỉ được kì hạn 1 tháng',
          },
        });
        return;
      }
      createCompany({
        name: this.companyName,
        address: this.address,
        taxCode: this.taxCode,
        products: this.listProduct,
        shortName: this.shortName,
        isActive: true,
      })
        .then(() => {
          this.refetchData();
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Tạo công ty thành công',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          });
          this.$bvModal.hide('modal-create-company');
        })
        .catch((err) => {
          console.log(err);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Tạo thất bại',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: err.response.data?.message,
            },
          });
          this.$bvModal.hide('modal-create-company');
        });
    },
    handleUpdateActive(id, val) {
      this.busy = true;
      updateCompany(id, {
        isActive: val,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Cập nhật thành công ',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          });
          this.busy = false;
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Cập nhật thất bại ',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: err.response.data?.message,
            },
          });
          this.busy = false;
        });
    },
    handleDeleteCompany(id) {
      this.companyId = id;
      this.$bvModal.show('modal-delete-company');
    },
    handleConfirmDelete() {
      deleteCompany(this.companyId)
        .then(() => {
          this.refetchData();
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Xoá công ty thành công',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          });
        })
        .catch((err) => {
          console.log(err);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Xoá thất bại',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: err.response.data?.message,
            },
          });
        });
    },
    myHandler(val) {
      this.$router.push({
        name: constRouter.PAYROLL_COMPANY_DETAIL.name,
        params: {
          id: val.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
.required {
  color: red;
  margin-left: 5px;
}
.relative {
  position: relative;
}
.absolute-percent {
  position: absolute;
  bottom: 8px;
  right: 20px;
}
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
